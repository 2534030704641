<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="课程概述" name="description">
        <el-form
          :label-position="'left'"
          label-width="80px"
          @submit.prevent="onSubmit"
          style="margin: 20px; width: 60%; min-width: 600px"
        >
          <el-form-item label="状态">
            <el-input
              :value="requestContent.status === 0 ? '已保存' : requestContent.status == 1 ? '已发布' : '新建'"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="课程名称">
            <el-input v-model="requestContent.courseName"></el-input>
          </el-form-item>
          <el-form-item label="课程副标题">
            <el-input v-model="requestContent.subTitle"></el-input>
          </el-form-item>
          <el-form-item label="原价">
            <el-input v-model="requestContent.showPrice"></el-input>
          </el-form-item>
          <el-form-item label="真实价/折扣价">
            <el-input v-model="requestContent.truePrice"></el-input>
          </el-form-item>
          <el-form-item label="老师ids">
            <el-input v-model="requestContent.teachers"></el-input>
          </el-form-item>
          <el-form-item label="淘宝购买">
            <el-input v-model="requestContent.taobaoLink"></el-input>
          </el-form-item>
          <el-form-item label="淘宝口令">
            <el-input v-model="requestContent.taobaoPasteCode"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="requestContent.status">
              <el-radio :label="0">保存</el-radio>
              <el-radio :label="1">发布</el-radio>
              <el-radio :label="2">停售</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="封面图">
            <el-row>
              <div class="coverImgCon">
                <span class="coverImg" @click="dialogShowCoverImgVisible = true">
                  <img :src="requestContent.coverUrl" />
                  <span>{{ requestContent.coverCosName }}</span>
                </span>
                <span style="margin-left: 60%">
                  <el-button @click="onAddCover" size="small" type="primary">选择封面图</el-button>
                </span>
              </div>
            </el-row>
            <!-- <el-button @click="onAddSchedule(index)" size="small">删除</el-button> -->
          </el-form-item>
          <el-form-item label="课程介绍">
            <aidaFeedEditor
              :openMd="true"
              :openHtml="true"
              :openCos="true"
              :openVod="false"
              :openPractice="false"
              v-model="requestContent.introduction"
            ></aidaFeedEditor>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="课程安排" name="schedule">
        <el-form
          :label-position="'left'"
          label-width="80px"
          @submit.prevent="onSubmit"
          style="margin: 20px; width: 60%; min-width: 600px"
        >
          <el-form-item v-for="(item, index) in requestContent.scheduleList" :label="index.toString()" :key="index">
            <div class="scheduleList">
              <el-row>
                <!-- <el-col :span="6"> -->
                <el-input type="textarea" v-model="item.scheduleName"></el-input>
                <el-radio-group v-model="item.authType" size="small" v-if="item.teachType != -1">
                  <el-radio-button label="0">购买学</el-radio-button>
                  <el-radio-button label="1">注册学</el-radio-button>
                </el-radio-group>
              </el-row>
              <el-row>
                <div class="teachType" v-if="item.teachType != -1">
                  <span v-if="item.teachType == 0">录播:{{ item.teachName }} </span>
                  <span v-if="item.teachType == 1">直播:{{ item.teachName }}</span>
                  <span v-if="item.teachType == 2">实践环节:{{ item.teachName }}</span>
                  <span v-if="item.teachType == 3">信息流对话式:{{ item.teachName }}</span>
                  <span v-if="item.teachType == 4">CF:{{ item.teachName }}</span>
                  <span style="margin-left:20px"> id:{{ item.teachId }} </span>
                  <span style="margin-left:20px">
                    <el-button @click="onSelectTeachFile(index)" size="small" type="primary">选择资源</el-button>
                  </span>
                </div>
              </el-row>
              <el-row style="margin-top: 10px; display: flex; justify-content: flex-end">
                <el-button @click="onAddIntroduction(index)" size="mini">插入介绍文案</el-button>
                <el-button @click="onAddSchedule(index)" size="mini">插入安排</el-button>
                <el-button @click="onMoveUpSchedule(index)" size="mini">上移</el-button>
                <el-button @click="onMoveDownSchedule(index)" size="mini">下移</el-button>
                <el-button @click="onDeleteSchedule(index)" size="mini">删除</el-button>
              </el-row>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="课程集合" name="courseList">
        <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
          <el-form-item label="课程集">
            <el-input type="textarea" placeholder="请输入内容" v-model="courseListVal" :rows="10"> </el-input>
          </el-form-item>
          <el-form-item label="课程类型">
            <el-radio-group v-model="requestContent.courseType">
              <el-radio :label="0">普通课程</el-radio>
              <el-radio :label="1">课程集合</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" height="80%">
        <aidaCosSelector v-model="cosRet" v-on:get-select="onGetSelectCos"></aidaCosSelector>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="dialogTeachVisible" width="80%" height="80%">
        <TeachSelector v-on:get-teach="onGetSelectTeach"></TeachSelector>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTeachVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogTeachVisible = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="预览" :visible.sync="dialogShowCoverImgVisible">
        <div slot="footer" class="dialog-footer">
          <img class="detailCoverImg" :src="requestContent.coverUrl" />
          <el-button @click="dialogShowCoverImgVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogShowCoverImgVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </el-tabs>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="saveContent">保存</el-button>
        <el-button @click="textToArrayList(courseListVal)">取消</el-button>
        <!-- <el-button @click="publiceContent" type="primary">立即发布</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import aidaFeedEditor from "@/component/aidaFeedEditor";
import aidaCosSelector from "@/component/aidaCosSelector.vue";
import TeachSelector from "@/component/teachSelector";
import { alertMessage } from "@/util/alertMessageUtil";
import { MarkdownFileType, TeachTypeEnum } from "@/util/EnumUtil";
import { aidaCourseApi } from "@/api/aidaCourseApi";
export default {
  components: {
    aidaFeedEditor,
    aidaCosSelector,
    TeachSelector,
  },
  data() {
    return {
      courseId: "0",
      courseListVal: "",
      activeName: "description",
      dialogVisible: false,
      cosRet: {},
      currentIndex: 0,
      recording: TeachTypeEnum.RECORDING,
      live: TeachTypeEnum.LIVE,
      Practice: TeachTypeEnum.PRACTICE,
      dialogShowCoverImgVisible: false,
      dialogTeachVisible: false,
      requestStatus: true,
      saveStatus: false,
      requestContent: {
        courseId: 0,
        courseName: "",
        subTitle: "",
        trumanCourseId: "",
        coverCosKey: "",
        coverUrl: "",
        createTime: 0,
        introduction: [
          {
            unitType: MarkdownFileType.MARKDOWN,
            unitId: 0,
            index: 0,
            content: "",
            fileName: "",
            url: "",
            markDown: {
              content: "",
            },
          },
        ],
        isDeleted: 0,
        scheduleList: [
          {
            scheduleId: 0,
            courseId: 0,
            authType: 0,
            teachId: 0,
            teachName: "",
            teachType: 0,
            scheduleName: "",
          },
        ],
        showPrice: null,
        status: 0,
        truePrice: null,
        updateTime: 0,
        teachers: "",
        duanshuLink: "",
        taobaoLink: "",
        taobaoPasteCode: "",
        courseList: [0],
        courseType: 0,
      },
    };
  },
  methods: {
    onAddIntroduction(index) {
      //章节里的介绍文案
      let length = this.requestContent.scheduleList.length;
      let obj = {
        scheduleId: length,
        courseId: this.courseId,
        authType: 0,
        teachId: 0,
        teachName: "",
        teachType: -1,
        scheduleName: "",
      };
      this.requestContent.scheduleList.splice(index + 1, 0, obj);
    },
    onAddSchedule(index) {
      let length = this.requestContent.scheduleList.length;
      let obj = {
        scheduleId: length,
        courseId: this.courseId,
        authType: 0,
        teachId: 0,
        teachName: "",
        teachType: 0,
        scheduleName: "",
      };
      this.requestContent.scheduleList.splice(index + 1, 0, obj);
    },
    onMoveUpSchedule(index) {
      if (index == 0) {
        alertMessage("无法再上移", "warning");
        return;
      }
      let temp = this.requestContent.scheduleList.splice(index - 1, 1);
      this.requestContent.scheduleList.splice(index, 0, temp[0]);
    },
    onMoveDownSchedule(index) {
      if (index == this.requestContent.scheduleList.length - 1) {
        alertMessage("无法再下移", "warning");
        return;
      }
      let temp = this.requestContent.scheduleList.splice(index, 1);
      this.requestContent.scheduleList.splice(index - 1, 0, temp[0]);
    },
    onDeleteSchedule(index) {
      if (this.requestContent.scheduleList.length == 1) {
        alertMessage("不能再删了", "warning");
        return;
      }
      this.requestContent.scheduleList.splice(index, 1);
    },
    onAddCover() {
      this.dialogVisible = true;
    },
    onSelectTeachFile(index) {
      this.dialogTeachVisible = true;
      this.currentIndex = index;
    },

    onGetSelectCos(val) {
      this.requestContent.coverCosName = val.fileName;
      this.requestContent.coverCosKey = val.cosKey;
      this.requestContent.coverUrl = val.url;
      this.dialogVisible = false;
    },
    onGetSelectTeach(val) {
      console.log("on get teach asset", val);
      this.requestContent.scheduleList[this.currentIndex].teachId = val.teachId;
      this.requestContent.scheduleList[this.currentIndex].teachName = val.teachName;
      this.requestContent.scheduleList[this.currentIndex].scheduleName = val.teachName;
      this.requestContent.scheduleList[this.currentIndex].teachType = val.teachType;
      this.requestContent.scheduleList[this.currentIndex].courseId = this.courseId;
      this.dialogTeachVisible = false;
    },
    saveContent() {
      this.submitContent();
    },
    submitContent() {
      console.log("_-this.requestContent--", this.requestContent);

      let alertText = "请填写课程名称";
      let alertType = "warning";
      this.requestStatus = true;
      if (!this.requestContent.courseName) {
        alertMessage(alertText, alertType);
        return;
      }

      alertText = "请填写原价";
      if (this.requestContent.truePrice === "") {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请填写折扣价";
      if (this.requestContent.showPrice === "") {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请选择封面图";
      if (!this.requestContent.coverUrl) {
        alertMessage(alertText, alertType);
        return;
      }
      //重置scheduleId
      for (let i = 0; i < this.requestContent.scheduleList.length; i++) {
        this.requestContent.scheduleList[i].scheduleId = i;
        this.requestContent.scheduleList[i].courseId = this.courseId;
      }
      console.log(this.requestContent.schedule);
      this.requestContent.courseList = this.textToArrayList(this.courseListVal);
      if (this.requestStatus) {
        aidaCourseApi.saveCourse(this.requestContent).then((ret) => {
          if (ret.code === 0) {
            alertMessage("保存成功，记得发布", "success");
            this.requestContent.courseId = ret.data.courseId;
            this.saveStatus = true;
          } else {
            alertMessage(ret.data.msg, "warning");
          }
        });
      }
    },
    textToArrayList(val) {
      let arr = val.split("\n").filter((x) => x > 0);
      return arr;
    },
  },
  mounted() {
    this.courseId = "0";
    if (this.$route.params.id) {
      this.courseId = this.$route.params.id;
      this.requestBtnText = "立即修改";
      aidaCourseApi.getCourseById(this.$route.params.id).then((ret) => {
        console.log("---ret.data.data", ret.data);
        if (ret.code === 0) {
          this.requestContent = ret.data;
          if (this.requestContent.introduction == null || this.requestContent.introduction.length == 0) {
            this.requestContent.introduction.push({
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            });
          }
          if (!this.requestContent.scheduleList || this.requestContent.scheduleList.length == 0) {
            this.requestContent.scheduleList = [
              {
                scheduleId: 0,
                courseId: this.courseId,
                authType: 0,
                teachId: 0,
                teachName: "",
                teachType: 0,
                scheduleName: "",
              },
            ];
          }
          this.courseListVal = this.requestContent.courseList.join("\n");
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.scheduleList {
  padding-bottom: 10px;
  margin-bottom: 25px;
  //border-bottom: 1px solid #646464;
}

.coverImgCon {
  border: 1px solid #f9f9f9;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  /* width: 55%; */
}

.coverImgCon :hover {
  cursor: pointer;
}

.coverImg img {
  width: 40px;
  height: 40px;
}

.coverImg {
  display: flex;
  align-items: center;
}

.coverImg :hover {
  cursor: pointer;
}

.detailCoverImg {
  width: 360px;
  height: 360px;
}

.teachType {
  border: 1px solid #dcdfe6;
}

.bottomBtn {
  padding-top: 8px;
  border-top: 1px solid #dcdfe6;
}
</style>
