<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="12">
          <el-form-item label="教学类型">
            <el-radio-group v-model="queryTeachType" size="small">
              <el-radio-button label="3">FEED</el-radio-button>
              <el-radio-button label="5">recite背诵</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="教学名称">
            <el-input v-model="queryTeachName" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="teachList" highlight-current-row style="width: 100%">
      <el-table-column prop="teachId" label="id"></el-table-column>
      <el-table-column prop="teachName" label="名称"></el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onSelect(scope.$index, scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { xteachApi } from "@/api/xteachApi";
import { epochToDateString } from "@/util/TimeUtil";

export default {
  data() {
    return {
      queryTeachType: 3,
      queryTeachName: "",
      teachList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    onQuery() {
      let start = (this.currentPage - 1) * 20;
      let len = 20;
      if (this.queryTeachName) {
        xteachApi
          .searchTeach(this.queryTeachType, this.queryTeachName, start, len)
          .then((ret) => {
            let { code, data, totalPage } = ret;
            if (code == 0) {
              this.teachList = data;
              this.totalPage = totalPage;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("搜索出错");
          });
      } else {
        xteachApi
          .listTeach(parseInt(this.queryTeachType), start, len)
          .then((ret) => {
            if (ret.code === 0) {
              console.log("list teach", ret);
              this.teachList = ret.data;
              this.totalPage = ret.totalPage;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("获取列表出错");
          });
      }
    },
    //选择
    onSelect(index, row) {
      console.log("teach selector, select", row);
      this.$emit("get-teach", row);
    },
  },
  watch: {
    queryTeachType: function(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.currentPage = 1;
      this.onQuery();
    },
    queryTeachName: function(newVal, oldVal) {
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
